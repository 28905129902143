import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['result', 'input']

  filterResults(event) {
    const query = event.target.value

    this.resultTargets.forEach(result => {
      if (result.dataset.resultId.toLowerCase().includes(query.toLowerCase())) {
        result.classList.remove('hidden')
      } else {
        result.classList.add('hidden')
      }
    })
  }
}
